import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "./style";

import { error } from "../../stores/notification/actions";

const Input = (props) => {
  const classes = styles();

  const [error, setError] = React.useState({
    error: false,
    message: "",
  });

  return (
    <Grid item xs={props.size} spacing={0} className={classes.input}>
      <TextField
        required
        fullWidth
        error={error.error}
        helperText={error.message}
        label={props.title}
        variant="outlined"
        value={props.value}
        onChange={(e) => {
          props.onChange(e);
          setError({ error: false, message: "" });
        }}
        onBlur={(e) => {
          const invalid = props.validate(e.target.value);
          setError({ error: invalid, message: invalid });
        }}
      />
    </Grid>
  );
};

const Shopify = (props) => {
  const classes = styles();

  const [form, setForm] = React.useState({
    url: {
      size: 12,
      title: "Zillow Listing",
      value: "",
      valid: false,
      validate: (t) => {
        if (t === "") return "Zillow Listing Url is Required";
        if (!t.match(/\d+_zpid/)) return "Invalid Zillow Listing URL";
        return null;
      },
    },
  });

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={8} className={classes.logins}>
        <div className={classes.header}>
          <img style={{ marginRight: 10 }} src="/assets/logo.png" width="60" />
          <Typography variant="h4">HouseMine</Typography>
        </div>
        <Typography variant="h5" className={classes.slogan}>
          Let's find you your dream home
        </Typography>
        <Paper elevation={3} className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {Object.keys(form)
                .slice(0, 1)
                .map((f) => (
                  <Input
                    key={f}
                    id={f}
                    size={form[f].size}
                    title={form[f].title}
                    value={form[f].value}
                    validate={form[f].validate}
                    fullWidth
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [f]: {
                          ...form[f],
                          value: e.target.value,
                          valid: !form[f].validate(e.target.value),
                        },
                      })
                    }
                  />
                ))}
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              const id = form.url.value.match(/\d+_zpid/);
              if (id) {
                props.history.push(`/zi/${id[0].replace("_zpid", "")}`);
              }
            }}
            disabled={
              !Object.values(form)
                .map((f) => Boolean(f.valid))
                .reduce((a, b) => a && b)
            }
            className={classes.button}
          >
            Analyze
          </Button>
        </Paper>
        <Typography style={{ marginTop: 20, fontSize: 20 }}>
          Check out our recommended listsings for{" "}
          <a href="/city/austin">Austin</a>, <a href="/city/dallas"> Dallas </a>{" "}
          and <a href="/city/denver">Denver </a>
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} className={classes.banner}></Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    error: (msg) => dispatch(error(msg)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shopify));
