import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";

const Types = {
  GET_PLACES_START: "GET_PLACES_START",
  GET_PLACES_SUCCESS: "GET_PLACES_SUCCESS",
  GET_PLACES_ERROR: "GET_PLACES_ERROR",
};

const { getPlacesStart, getPlacesSuccess, getPlacesError } = createActions(
  Types.GET_PLACES_START,
  Types.GET_PLACES_SUCCESS,
  Types.GET_PLACES_ERROR
);

const getPlaces = (lat, lon, zipcode) => (dispatch, getState) => {
  dispatch(getPlacesStart());

  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/n/places?lat=${lat}&lon=${lon}&zipcode=${zipcode}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch neighborhood places";
      dispatch(error(msg));

      return dispatch(
        getPlacesError(err || { message: "Unable to fetch rates" })
      );
    }

    return dispatch(getPlacesSuccess(JSON.parse(body)));
  });
};

export { Types, getPlaces };
