import React from "react";
import numeral from "numeral";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import styles from "./style";

const Listing = ({ id, rank, listing, region, history, onClick }) => {
  const classes = styles();

  const homeTypes = {
    CONDO: "Condo",
    COOPERATIVE: "Coop",
    MULTIFAMILY: "Multi Family",
    TOWNHOUSE: "Townhouse",
    APARTMENT: "Apartment",
    SINGLE_FAMILY: "Single Family",
  };

  return (
    <Paper
      onClick={() => {
        const win = window.open(`/zi/${id}`, "_blank");
        win.focus();
      }}
      elevation={10}
      sx={{ maxWidth: 300 }}
      className={classes.root}
    >
      <div className={classes.header}>
        <CardHeader
          style={{ overflow: "hidden" }}
          avatar={<Avatar className={classes.avatar}>{rank}</Avatar>}
          title={listing.streetAddress}
          subheader={`${listing.city}, ${listing.zipCode}`}
        />
        <div className={classes.info}>
          <Chip label={homeTypes[listing.homeType.toUpperCase()]} />
        </div>
      </div>
      <CardMedia
        component="img"
        height="194"
        image={listing.photos[0]}
        alt="Comparable"
      />
      <CardContent>
        <div className={classes.facts}>
          <div className={classes.fact}>
            <Icon fontSize="medium" className={classes.icon}>
              sell
            </Icon>
            <Typography variant="subtitle1">{`$${numeral(listing.price)
              .format("0.0a")
              .toUpperCase()}`}</Typography>
          </div>
          <div className={classes.fact}>
            <Icon fontSize="medium" className={classes.icon}>
              payments
            </Icon>
            <Typography variant="subtitle1">{`$${numeral(listing.rent)
              .format("0.0a")
              .toUpperCase()}`}</Typography>
          </div>
          <div className={classes.fact}>
            <Icon fontSize="medium" className={classes.icon}>
              crop
            </Icon>
            <Typography variant="subtitle1">{listing.livingArea}</Typography>
          </div>
        </div>
      </CardContent>
    </Paper>
  );
};

export default Listing;
