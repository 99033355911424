import React from "react";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Route, Switch, withRouter } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";

import styles from "./style";

import AppBar from "../appBar";
import Listing from "../h_listing";
import ZListing from "../z_listing";
import Search from "../search";
import Notification from "../common/notification";
import Lookup from "../lookup";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#8e99f3",
      main: "#5c6bc0",
      dark: "#26418f",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
    },
  },
  typography: {
    fontFamily: "'Barlow', sans-serif;",
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "white",
        backgroundColor: "#8e99f3",
      },
    },
  },
});

const ZillowListing = (props) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBmLv5cguT0syxbojA1Ro1Sm4sbUlprwjQ">
      <ZListing {...props} />
    </LoadScript>
  );
};

const App = function (props) {
  const classes = styles();

  const appRouting = (
    <Switch className={classes.app}>
      <Route
        path="/"
        render={() => (
          <Switch className={classes.app}>
            <Route exact path="/" component={Lookup} />
            <AppBar>
              <Route exact path="/:id" component={Listing} />
              <Route exact path="/zi/:id" component={ZillowListing} />
              <Route exact path="/city/:city" component={Search} />
            </AppBar>
          </Switch>
        )}
      ></Route>
    </Switch>
  );

  return (
    <MuiThemeProvider theme={theme}>
      <Notification />
      {appRouting}
    </MuiThemeProvider>
  );
};

export default withRouter(App);
