import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import numeral from "numeral";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles({
  root: {
    width: 400,
    padding: 10,
  },
  thumb: {
    width: 20,
    height: 20,
    marginTop: -8,
    marginLeft: -10,
    color: "#ff7961",
  },
  track: {
    height: 4,
    borderRadius: 4,
    color: "#f44336",
  },
  rail: {
    height: 4,
    borderRadius: 4,
    color: "#ff7961",
  },
  markLabel: {
    fontSize: 15,
    marginTop: 10,
  },
  text: {
    padding: 3,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: 600,
    whiteSpace: "pre-line",
  },
  tooltip: {
    backgroundColor: "#ba000d",
    fontSize: 16,
  },
});

function format(value) {
  const format = value < 1000000 ? "0a" : "0.0a";
  return numeral(value).format(format).toUpperCase();
}

export default function RangeSlider(props) {
  const classes = useStyles();

  const lower = props.range[0];
  const upper = props.range[1];

  const delta = Math.round((upper - lower) / 5 / 50000) * 50000;

  const marks = [...Array(5).keys()].map((tick) => ({
    value: tick * delta,
    label: `${format(tick * delta)}`,
  }));

  const lowerRange = format(props.value[0]);
  const upperRange = format(props.value[1]);

  return (
    <div className={classes.root}>
      <Typography style={{ marginBottom: 20 }} id="range-slider" gutterBottom>
        <strong>{props.title}</strong>: <strong>${lowerRange}</strong> -{" "}
        <strong>${upperRange}</strong>
      </Typography>
      <Slider
        classes={{
          thumb: classes.thumb,
          track: classes.track,
          rail: classes.rail,
          markLabel: classes.markLabel,
          valueLabel: classes.valueLabel,
        }}
        min={lower}
        max={upper}
        step={delta / 10}
        marks={[...marks, { value: upper, label: `$${format(upper)}` }]}
        value={props.value}
        onChange={(e, v) => props.onChange(v)}
        valueLabelDisplay="auto"
        valueLabelFormat={(v) => `$ ${format(v)}`}
        ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  );
}

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useStyles();

  return (
    <Tooltip
      classes={classes}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}
