import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: 180,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
