import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  rates: [],
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [Types.GET_RATES_START]: (state, action) => state.set("loading", true),
    [Types.GET_RATES_SUCCESS]: (state, action) =>
      state
        .set("rates", action.payload)
        .set("loading", false)
        .set("error", null),
    [Types.GET_RATES_ERROR]: (state, action) =>
      state.set("rates", []).set("loading", false).set("error", action.payload),
  },
  INITIAL_STATE
);

export default reducer;
