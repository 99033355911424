import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    padding: 10,
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  rank: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.light,
  },
  row: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  stat: {
    display: "flex",
    marginRight: 15,
  },
  icon: {
    color: "grey",
    marginLeft: 5,
    marginRight: 5,
  },
  facts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
