import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";
import { getCompDetail } from "../comparables/actions";
import { getRates } from "../rates/actions";
import { getPlaces } from "../places/actions";

const Types = {
  GET_LISTING_START: "GET_LISTING_START",
  GET_LISTING_SUCCESS: "GET_LISTING_SUCCESS",
  GET_LISTING_ERROR: "GET_LISTING_ERROR",
};

const { getListingStart, getListingSuccess, getListingError } = createActions(
  Types.GET_LISTING_START,
  Types.GET_LISTING_SUCCESS,
  Types.GET_LISTING_ERROR
);

const getListing = (id) => (dispatch, getState) => {
  dispatch(getListingStart());
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/api/listings/${id}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch listings";
      dispatch(error(msg));
      return dispatch(
        getListingError(err || { message: "Unable to fetch listings" })
      );
    }
    return dispatch(getListingSuccess(JSON.parse(body).listing));
  });
};

const getZillowListing = (id) => (dispatch, getState) => {
  dispatch(getListingStart());
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/z/${id}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch listings";
      dispatch(error(msg));
      return dispatch(
        getListingError(err || { message: "Unable to fetch listings" })
      );
    }

    const listing = JSON.parse(body).listing;
    const places = JSON.parse(body).places;

    listing.comparables.forEach((comp) => dispatch(getCompDetail(comp.zpid)));
    dispatch(getRates(listing.state));
    dispatch(getPlaces(listing.latitude, listing.longitude, listing.zipCode));

    return dispatch(getListingSuccess({ listing, places }));
  });
};

export { Types, getListing, getZillowListing };
