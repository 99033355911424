import React from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import NoData from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import numeral from "numeral";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import Tooltip from "../tooltip";

import styles from "./style";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);
NoData(Highcharts);

const Chart = (props) => {
  const classes = styles();

  function log(num, base = 20) {
    return Math.log(num) / Math.log(base);
  }

  function exp(num, base = 20) {
    return Math.exp(num * Math.log(base));
  }

  function tooltip() {
    return `<p>${this.series.name}: <b>${numeral(
      this.y > 0 ? exp(this.y) : -exp(-this.y)
    )
      .format("0.0a")
      .toUpperCase()}</b></p>`;
  }

  function yAxis() {
    return numeral(this.value).format("0.a").toUpperCase();
  }

  const xValues = props.x || [];
  const yValues = props.y || [];

  const options = {
    chart: {
      type: "column",
    },
    title: {
      enabled: false,
      text: "",
      align: "center",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
      y: 5,
    },
    yAxis: {
      gridLineWidth: 2,
      lineWidth: 1,
      title: {
        enabled: false,
      },
      labels: {
        formatter: yAxis,
        enabled: false,
        style: {
          fontWeight: "bold",
        },
      },
    },
    xAxis: {
      gridLineWidth: 2,
      lineWidth: 1,
      tickInterval: 3,
      categories: xValues,
      startOnTick: true,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
      },
      column: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    tooltip: {
      pointFormatter: tooltip,
    },
    series: yValues.map((v) => ({
      ...v,
      name: v.name,
      data: v.data.map((p) => (p < 0 ? -log(-p) : log(p))),
    })),
    lang: {
      noData:
        "We do not have enough data on this property for a solid estimation",
    },
    credits: {
      enabled: false,
    },
  };

  const sum = (arr) =>
    numeral(arr.reduce((a, b) => a + b))
      .format("0.0a")
      .toUpperCase();

  return (
    <div>
      {props.controls && (
        <div className={classes.controls}>
          {props.controls.map((c) => (
            <div className={classes.control}> {c} </div>
          ))}
        </div>
      )}
      {yValues
        .filter((v) => v.total)
        .map((v) => (
          <div>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Total {v.name}:
              <span style={{ color: v.color }}> {sum(v.data)}</span>
            </Typography>
          </div>
        ))}
      <div className={classes.header}>
        <Typography></Typography>
        <Typography className={classes.title}>{props.title}</Typography>
        {/*        <Tooltip title={props.helper} placement="top">
          <Icon style={{ cursor: "pointer" }}>help_outline</Icon>
        </Tooltip>*/}
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Chart;
