import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: 250,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  input: {
    width: 100,
  },
}));
