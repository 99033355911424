import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import Range from "../../../common/range";
import Icon from "@material-ui/core/Icon";

import { updateBaths } from "../../../../stores/controls/actions";

const BathsControl = (props) => {
  return (
    <Control id="price" name="Bathrooms" icon={<Icon>wc</Icon>} {...props}>
      <Range range={props.baths} onChange={props.updateBaths} />
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    baths: state.ControlsStore.get("baths"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateBaths: (range) => dispatch(updateBaths(range)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BathsControl);
