import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  listing: {},
  places: [],
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [Types.GET_LISTING_START]: (state, action) => state.set("loading", true),
    [Types.GET_LISTING_SUCCESS]: (state, action) =>
      state
        .set("listing", action.payload.listing)
        .set("places", action.payload.places)
        .set("loading", false)
        .set("error", null),
    [Types.GET_LISTING_ERROR]: (state, action) =>
      state.set("listings", {}).set("loading", false).set("error", null),
  },
  INITIAL_STATE
);

export default reducer;
