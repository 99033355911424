import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";

const Types = {
  GET_METRICS_START: "GET_METRICS_START",
  GET_METRICS_SUCCESS: "GET_METRICS_SUCCESS",
  GET_METRICS_ERROR: "GET_METRICS_ERROR",
};

const { getMetricsStart, getMetricsSuccess, getMetricsError } = createActions(
  Types.GET_METRICS_START,
  Types.GET_METRICS_SUCCESS,
  Types.GET_METRICS_ERROR
);

const getMetrics = () => (dispatch, getState) => {
  dispatch(getMetricsStart());
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/api/metrics`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch metrics";
      dispatch(error(msg));
      return dispatch(
        getMetricsError(err || { message: "Unable to fetch listings" })
      );
    }
    return dispatch(getMetricsSuccess(JSON.parse(body).metrics));
  });
};

export { Types, getMetrics };
