import React from "react";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";

import styles from "./style";

const Board = (props) => {
  const classes = styles();

  const renderChildren = () => {
    if (Array.isArray(props.children)) {
      return props.children.map((child, i) => (
        <div key={i} className={classes.child}>
          {child}
        </div>
      ));
    }
    return <div className={classes.child}>{props.children}</div>;
  };

  const loading = <LinearProgress size={30} />;

  return (
    <Paper elevation={10} className={classes.root}>
      <div className={classes.header}>
        <div className={classes.left}>
          <Icon fontSize="medium" className={classes.icon}>
            {props.icon}
          </Icon>
          <Typography variant="button" className={classes.title}>
            {props.title}
          </Typography>
        </div>
        {props.action}
      </div>
      {props.loading ? loading : renderChildren()}
    </Paper>
  );
};

export default Board;
