import { error } from "../notification/actions";

const respond = (err, response, dispatch, callback) => {
  if (err) {
    dispatch(error(`Unknown Error: ${err.message}`));
  } else if (response.statusCode === 401) {
    dispatch(error("User session expired. Please log in again."));
  } else if (response.statusCode === 403) {
    dispatch(error("You are not authorized to perform that action."));
  } else if (response.statusCode === 404) {
    dispatch(error("The requested resource was not found."));
  } else if (response.statusCode !== 200) {
    const errors = response.body.errors || [];

    const err =
      response.body.message ||
      response.body.error ||
      errors.map((err) => Object.values(err)[0])[0] ||
      "Something unexpected happenned. Please try again later.";
    dispatch(error(err));
  } else {
    callback();
  }
};

export default respond;
