import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  alerts: {
    width: "100%",
    "& > * + *": {
      marginTop: 10,
    },
  },
}));
