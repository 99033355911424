import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  regions: [],
  loading: false,
  error: null,
  fetched: false,
  changed: false,
});

const reducer = handleActions(
  {
    [Types.GET_AREAS_START]: (state, action) => state.set("loading", true),
    [Types.GET_AREAS_SUCCESS]: (state, action) =>
      state
        .set(
          "regions",
          action.payload.map((a) => ({ ...a, enabled: true }))
        )
        .set("loading", false)
        .set("error", null)
        .set("fetched", true),
    [Types.GET_AREAS_ERROR]: (state, action) =>
      state.set("regions", []).set("loading", false).set("error", null),
    [Types.TOGGLE_AREAS]: (state, action) =>
      state.set(
        "regions",
        state.get("regions").map((r) => ({ ...r, enabled: action.payload }))
      ),
    [Types.UPDATE_AREA]: (state, action) => {
      const index = state
        .get("regions")
        .findIndex((h) => h.id === action.payload.type);
      const areas = state.get("regions");
      areas[index] = { ...areas[index], enabled: action.payload.value };
      return state.set("regions", areas).set("changed", !state.get("changed"));
    },
    [Types.UPDATE_BOROUGH]: (state, action) => {
      const regions = state.get("regions").map((r) => {
        return {
          ...r,
          enabled:
            r.neighborhood === action.payload.type
              ? action.payload.value
              : r.enabled,
        };
      });
      return state
        .set("regions", regions)
        .set("changed", !state.get("changed"));
    },
  },
  INITIAL_STATE
);

export default reducer;
