import React from "react";
import Alert from "@material-ui/lab/Alert";

import styles from "./style";

const Insights = (props) => {
  const classes = styles();

  const yields = props.comps.map((c) => c.netRentYield).sort();

  const betterYields = props.comps.filter(
    (c) => c.netRentYield > props.listing.netRentYield
  );
  const percentageBetter = (
    (betterYields.length / yields.length) * 100 +
    1
  ).toFixed(0);

  const getSeverity = () => {
    if (percentageBetter < 20) return "success";
    if (percentageBetter < 60) return "warning";
    return "error";
  };

  const getLabel = () => {
    if (percentageBetter < 20) return `top ${percentageBetter}%`;
    return `bottom ${100 - percentageBetter}%`;
  };

  return (
    <div className={classes.alerts}>
      {!props.listing.livingArea && (
        <Alert className={classes.alert} severity="warning">
          Missing Square Footage Information - Contact your broker for
          clarification
        </Alert>
      )}
      {props.listing.hoaFees === 0 && (
        <Alert className={classes.alert} severity="warning">
          Missing HOA Information - Contact your broker for clarification
        </Alert>
      )}
      {(!props.listing.rent || props.listing.rent === 0) && (
        <Alert className={classes.alert} severity="warning">
          Missing Rent Information - Could not estimate montly rental for the
          property
        </Alert>
      )}
      {props.listing.propertyTax === 0 && (
        <Alert className={classes.alert} severity="warning">
          Missing Property Tax Information - Contact your broker for
          clarification
        </Alert>
      )}
      {/*      <Alert className={classes.alert} severity={getSeverity()}>
        Your property is in the <strong>{getLabel()}</strong> of listings in its
        area
      </Alert>*/}
    </div>
  );
};

export default Insights;
