import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  AdvancedMarkerElement,
  OverlayView,
} from "@react-google-maps/api";
import {
  faHouseUser,
  faCartShopping,
  faBus,
  faTrainSubway,
} from "@fortawesome/free-solid-svg-icons";

import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

import Indicator from "../../common/indicator";

import theme from "./theme.json";

const Place = ({ place, icon, color }) => {
  return (
    <Marker
      position={{
        lng: place.geometry.location.lng,
        lat: place.geometry.location.lat,
      }}
      icon={{
        path: icon.icon[4],
        fillColor: color,
        fillColor: color,
        fillOpacity: 1,
        scale: 0.04,
      }}
    >
      <OverlayView
        position={{
          lng: place.geometry.location.lng,
          lat: place.geometry.location.lat,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          style={{
            marginTop: 25,
            marginLeft: -place.name.length * 2,
            padding: 5,
            borderRadius: 5,
            backgroundColor: color,
            color: "white",
          }}
        >
          <span>{place.name}</span>
        </div>
      </OverlayView>
    </Marker>
  );
};

const Map = (props) => {
  var description = props.places.description;
  props.places.groceries.forEach((p) => {
    console.log(p.name);

    description = description.replace(p.name, `<strong>${p.name}</strong>`);
  });

  return (
    <>
      <div
        style={{ fontSize: 18 }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          marginTop: 40,
        }}
      >
        {props.places.groceries.length !== 0 && (
          <Indicator
            icon="shopping_cart"
            textIcon="directions_walk"
            color="#008000"
            value={`${props.places.groceries[0].walking} min`}
            subvalue={props.places.groceries[0].name}
          />
        )}
        {props.places.busStations.length !== 0 && (
          <Indicator
            icon="directions_bus"
            textIcon="directions_walk"
            color="#0000FF"
            value={`${props.places.busStations[0].walking} min`}
            subvalue={props.places.busStations[0].name}
          />
        )}
        {props.places.subwayStations.length !== 0 && (
          <Indicator
            icon="train"
            textIcon="directions_walk"
            color="#CC5500"
            value={`${props.places.subwayStations[0].walking} min`}
            subvalue={props.places.subwayStations[0].name}
          />
        )}
        {/*        <Indicator
          icon="flight"
          color="#888888"
          value={`${getDistance(props.listing, props.places.airports[0])} mi`}
        />*/}
      </div>
      <Paper>
        <LoadScript googleMapsApiKey="AIzaSyBmLv5cguT0syxbojA1Ro1Sm4sbUlprwjQ">
          <GoogleMap
            mapContainerStyle={{ height: 500, width: "100%" }}
            center={{
              lat: props.listing?.location?.coordinates[1],
              lng: props.listing?.location?.coordinates[0],
            }}
            zoom={15.3}
            options={{
              styles: theme,
              scrollwheel: false,
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              panControl: true,
            }}
          >
            <Marker
              position={{
                lng: props.listing?.location?.coordinates[0],
                lat: props.listing?.location?.coordinates[1],
              }}
              icon={{
                path: faHouseUser.icon[4],
                fillColor: "#FF0000",
                fillColor: "#FF0000",
                fillOpacity: 1,
                scale: 0.07,
              }}
            />
            {props.places.groceries.map((place) => (
              <Place place={place} icon={faCartShopping} color="#008000" />
            ))}

            {props.places.busStations.map((place) => (
              <Place place={place} icon={faBus} color="#0000FF" />
            ))}

            {props.places.subwayStations.map((place) => (
              <Place place={place} icon={faTrainSubway} color="#CC5500" />
            ))}
          </GoogleMap>
        </LoadScript>
      </Paper>
    </>
  );
};

export default Map;
