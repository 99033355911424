import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";
import { updatePriceRange, updatePrice } from "../controls/actions";

const Types = {
  GET_LISTINGS_START: "GET_LISTINGS_START",
  GET_LISTINGS_SUCCESS: "GET_LISTINGS_SUCCESS",
  GET_LISTINGS_ERROR: "GET_LISTINGS_ERROR",
};

const { getListingsStart, getListingsSuccess, getListingsError } =
  createActions(
    Types.GET_LISTINGS_START,
    Types.GET_LISTINGS_SUCCESS,
    Types.GET_LISTINGS_ERROR
  );

const getListings = (city) => (dispatch, getState) => {
  dispatch(getListingsStart());
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/l/listings`,
    method: "POST",
    json: {
      city: city,
      price: getState().ControlsStore.get("price"),
      hoa: getState().ControlsStore.get("hoa"),
      beds: getState().ControlsStore.get("beds"),
      baths: getState().ControlsStore.get("baths"),
      types: getState()
        .ControlsStore.get("homeType")
        .filter((t) => t.enabled)
        .map((t) => t.value),
      regions: getState()
        .RegionsStore.get("regions")
        .filter((r) => r.enabled)
        .map((t) => t.id),
    },
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch listings";
      dispatch(error(msg));
      return dispatch(
        getListingsError(err || { message: "Unable to fetch listings" })
      );
    }

    const minPrice = Math.min(...body.listings.map((l) => l.price));
    const maxPrice = Math.max(...body.listings.map((l) => l.price));

    return dispatch(getListingsSuccess(body.listings));
  });
};

export { Types, getListings };
