import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    position: "fixed",
    height: "100%",
  },
  banner: {
    backgroundColor: "#8e99f3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginTop: 20,
  },
  logins: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    marginRight: 10,
  },
  form: {
    padding: 20,
    borderRadius: 10,
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    marginBottom: 40,
  },
  button: {
    color: "white",
    marginTop: 20,
    fontWeight: "bold",
  },
  slogan: {
    marginBottom: 50,
  },
  input: {
    padding: 0,
    margin: 0,
  },
}));
