import React from "react";

import { irr } from "node-irr";
import Grid from "@material-ui/core/Grid";

import Pie from "../../common/pie";
import Gauge from "../../common/gauge";

import model from "./homeFinance";

const Totals = (props) => {
  const sum = (arr, key) => arr.reduce((a, b) => a + (b[key] || 0), 0);

  const { listing } = props;
  const specs = model({
    rent: props.rent,
    price: props.price,
    insurance: listing.insurance,
    taxes: listing.propertyTax,
    hoa: listing.hoaFees,
    n: props.years,
    resale: props.resale,
    downpaymentRate: props.downpayment / 100,
    mortgageRate: props.mortageRate / 100,
    extraPayments: props.extraPayments,
    yearlyAppreciation: props.appreciation / 100,
  });

  const month = specs[0];
  const monthly_payments = [
    {
      name: "Interest",
      y: month.interest / 12,
      color: "#960E0E",
    },
    {
      name: "Charges",
      y: listing.hoaFees + listing.insurance,
      color: "#D50A0A",
    },
    {
      name: "Principal",
      y: month.principal / 12,
      color: "#26418f",
    },
    {
      name: "Taxes",
      y: listing.propertyTax,
      color: "#FF0F0F",
    },
  ];

  const total_payments = [
    {
      name: "Interest",
      y: sum(specs, "interest"),
      color: "#960E0E",
    },
    {
      name: "Charges",
      y: sum(specs, "expenses"),
      color: "#D50A0A",
    },
    {
      name: "Principal",
      y: sum(specs, "principal"),
      color: "#26418f",
    },
    {
      name: "Down",
      y: (props.downpayment * props.price) / 100,
      color: "#FFA500",
    },
    {
      name: "Property Tax",
      y: sum(specs, "propertyTaxes"),
      color: "#FF0F0F",
    },
    {
      name: "Income Tax",
      y: sum(specs, "rentalIncomeTax"),
      color: "#FF0F0F",
    },
    {
      name: "Fees",
      y: sum(specs, "fees"),
      color: "#FF0F0F",
      distance: "30",
    },
  ];

  // const burn_rate = [
  //   {
  //     name: "Rent",
  //     y: sum(specs, "rentalIncome"),
  //     color: "#FFA500",
  //   },
  //   {
  //     name: "Buy",
  //     y:
  //       sum(specs, "propertyTaxes") +
  //       sum(specs, "interest") +
  //       sum(specs, "expenses"),
  //     color: "#960E0E",
  //   },
  // ];

  const cash_flow = [
    {
      name: "Rent",
      y: sum(specs, "rentalIncome"),
      color: "#8e99f3",
    },
    {
      name: "Resale",
      y: specs[specs.length - 1].appreciation - specs[specs.length - 1].loan,
      color: "#26418f",
    },
  ];

  const total_inconme =
    sum(specs, "rentalIncome") +
    specs[specs.length - 1].appreciation -
    specs[specs.length - 1].loan;
  const cost = Math.abs(
    sum(specs, "interest") +
      sum(specs, "expenses") +
      sum(specs, "principal") -
      (props.downpayment * props.price) / 100 +
      sum(specs, "propertyTaxes") -
      sum(specs, "fees") -
      sum(specs, "rentalIncomeTax")
  );

  const roi = (((total_inconme - cost) / cost) * 100) / props.years;
  const cash_multiple = total_inconme / cost;

  const periods = specs.map((s) => s.netIncome);
  const interal_rr = irr(periods) * 100;

  const breakeven_year =
    new Date().getFullYear() + specs.findIndex((s) => s.netIncome >= 0);

  return (
    <Grid container spacing={5} style={{ marginBottom: 20 }}>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Pie
          name="Monthly Payments"
          series={monthly_payments}
          format="${y:.0f}"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Pie name="Total Payments" series={total_payments} format="${y:.0f}" />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Pie name="Cash Flow" series={cash_flow} format="${y:.0f}" />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Gauge
          good
          name="Yearly ROI"
          format="{y:.1f}%"
          min={0}
          value={roi}
          max={10}
          height={230}
          fontSize={20}
          innerRadius={50}
          outerRadius={60}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Gauge
          good
          name="Equity Multiple"
          format="{y:.2f}X"
          min={0}
          value={cash_multiple}
          max={3}
          height={230}
          marginTop={40}
          fontSize={20}
          innerRadius={50}
          outerRadius={60}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Gauge
          good
          name="IRR"
          format="{y:.2f}%"
          min={0}
          value={interal_rr}
          max={10}
          height={230}
          marginTop={40}
          fontSize={20}
          innerRadius={50}
          outerRadius={60}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
        <Gauge
          name="Breakeven Year"
          format="{y}"
          min={new Date().getFullYear()}
          value={breakeven_year}
          max={new Date().getFullYear() + props.years}
          height={230}
          marginTop={40}
          fontSize={20}
          innerRadius={50}
          outerRadius={60}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Totals);
