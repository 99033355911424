import React from "react";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

import Tooltip from "../tooltip";

import styles from "./style";

const Stat = (props) => {
  const classes = styles();

  return (
    <Tooltip interactive title={props.description}>
      <Paper elevation={6} className={classes.root}>
        <Icon>{props.icon}</Icon>
        <Typography className={classes.text} variant="overline">
          {props.value}
        </Typography>
      </Paper>
    </Tooltip>
  );
};

export default Stat;
