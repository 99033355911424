import React from "react";

import Grid from "@material-ui/core/Grid";
import Gauge from "../../common/gauge";

const Metrics = (props) => {
  const getZestimateLabel = () => {
    if (props.listing.zestimateVariance > 0) return "Underpriced";
    if (props.listing.zestimateVariance < 0) return "Overpriced";
    return "Fairly Priced";
  };

  const comparables = (props.listing.comparables || []).filter(
    (c) => c.livingArea
  );
  console.log(comparables);

  return (
    <Grid container style={{ display: "flex", justifyContent: "space-around" }}>
      {props.listing.livingArea && (
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Gauge
            name="Price/Sf"
            format="${y:.0f}"
            min={0}
            value={props.listing?.price / props.listing.livingArea}
            max={Math.max(...comparables.map((c) => c.price / c.livingArea))}
            description="Price per square foot"
          />
        </Grid>
      )}
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Gauge
          good
          name="Rental Income"
          format="${y:.0f}"
          min={0}
          value={props.rent}
          // We do not have the rent information after getting comps form zillow in the same call. We need to fix the UI/UXs
          // max={Math.max(...props.comps.map((c) => c.rentZestimate))}
          description="Estimated montly tental income"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Gauge
          good={getZestimateLabel() === "Underpriced"}
          name={getZestimateLabel()}
          format="{y:.0f}%"
          min={0}
          value={Math.abs(props.listing?.zestimateVariance * 100)}
          max={5}
          description="Property value relative to its actual value"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Gauge
          good
          name="Cap Rate"
          format="{y:.0f}%"
          min={0}
          value={props.listing?.netRentYield * 100}
          max={10}
          description="Property rental yield"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Gauge
          good
          name="Appreciation"
          format="{y:.0f}%"
          min={0}
          value={Math.ceil(props.listing?.yearlyAppreciation * 100)}
          max={10}
          description="Property yearly appreciation"
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Metrics);
