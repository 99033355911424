import { createActions } from "redux-actions";

const Types = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  LOADING: "LOADING",
  CLEAR_SUCCESS: "CLEAR_SUCCESS",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_WARNING: "CLEAR_WARNING",
  CLEAR_LOADING: "CLEAR_LOADING",
};

const {
  success,
  error,
  warning,
  loading,
  clearSuccess,
  clearError,
  clearWarning,
  clearLoading,
} = createActions(
  Types.SUCCESS,
  Types.ERROR,
  Types.WARNING,
  Types.LOADING,
  Types.CLEAR_SUCCESS,
  Types.CLEAR_ERROR,
  Types.CLEAR_WARNING,
  Types.CLEAR_LOADING
);

export {
  Types,
  success,
  error,
  warning,
  loading,
  clearSuccess,
  clearError,
  clearWarning,
  clearLoading,
};
