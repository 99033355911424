import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  paper: {
    padding: 30,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 10,
  },
  address: {
    marginTop: 0,
    marginBottom: 0,
  },
  metric: {
    display: "flex",
    justifyContent: "center",
  },
  legend: {
    justifySelf: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  scores: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
}));
