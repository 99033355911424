import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Control from "../../../common/control";
import Icon from "@material-ui/core/Icon";

import { updateCity } from "../../../../stores/controls/actions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 100,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CitiesControl = (props) => {
  const classes = useStyles();
  return (
    <Control
      id="cities"
      name="Cities"
      icon={<Icon>location_on</Icon>}
      {...props}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          style={{ height: 40 }}
          value={props.city}
          onChange={(e) => props.updateCity(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value={"atlanta"}>Atlanta</MenuItem>
          <MenuItem value={"austin"}>Austin</MenuItem>
          <MenuItem value={"dallas"}>Dallas</MenuItem>
          <MenuItem value={"denver"}>Denver</MenuItem>
          <MenuItem value={"saltlake"}>Salt Lake</MenuItem>
          <MenuItem value={"tampa"}>Tampa</MenuItem>
        </Select>
      </FormControl>
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    city: state.ControlsStore.get("city"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCity: (city) => dispatch(updateCity(city)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CitiesControl);
