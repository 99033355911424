import React from "react";
import numeral from "numeral";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";

import Gauge from "../../common/gauge";

import styles from "./style";

const Listing = ({ id, rank, listing, region, history, onClick }) => {
  const classes = styles();

  const NAMES = {
    san_francisco: "San Francisco",
    new_york: "New York",
    atlanta: "Atlanta",
    austin: "Austin",
    dallas: "Dallas",
    denver: "Denver",
    saltlake: "Salt Lake City",
    tampa: "Tampa",
    miami: "Miami",
    chicago: "Chicago",
    montreal: "Montreal",
    nashville: "Nashville"
  };

  const getZestimateLabel = () => {
    if (listing.variance === 0) return "Fairly Priced";
    if (listing.variance > 0) return "Underpriced";
    if (listing.variance < 0) return "Overpriced";
  };

  return (
    <Paper
      onClick={() => {
        if (listing.url.includes("realtor")) {
          const win = window.open(listing.url, "_blank");
          win.focus();
        } else {
          const win = window.open(`/zi/${id}`, "_blank");
          win.focus();
        }
      }}
      elevation={10}
      sx={{ maxWidth: 300 }}
      className={classes.root}
    >
      <div className={classes.header}>
        <CardHeader
          avatar={<Avatar className={classes.avatar}>{rank}</Avatar>}
          title={
            listing.regionName != "" ? listing.regionName : region.neighborhood
          }
          subheader={`${NAMES[listing.city]}, ${listing.zipcode}`}
        />
        <div className={classes.info}>
          <Typography
            variant="button"
            style={{ marginRight: 20, fontSize: 15, fontWeight: "bold" }}
          >
            {listing.homeType}
          </Typography>
        </div>
      </div>
      <CardMedia component="img" image={listing.image} />
      <CardContent className={classes.facts}>
        <div>
          <div>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 20, fontWeight: "bold" }}
            >{`$${listing.price.toLocaleString()} USD`}</Typography>
          </div>
          <div>
            <Typography variant="subtitle1" style={{ fontSize: 16 }}>
              {listing.bedrooms} Beds &nbsp;| &nbsp; {listing.bathrooms} Baths
              &nbsp; | &nbsp; {listing.livingArea} sqft
            </Typography>
          </div>
        </div>
        <div>
          <Gauge
            good
            name="Yield"
            height={55}
            width={55}
            fontSize={14}
            format="{y:.0f}%"
            min={0}
            value={listing.rentalYield * 100}
            max={12}
            description="The property rental yield"
          />
        </div>
      </CardContent>
    </Paper>
  );
};

export default Listing;
