import React from "react";
import { connect } from "react-redux";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import {
  clearSuccess,
  clearError,
  clearWarning,
} from "../../../stores/notification/actions";

function Alert(props) {
  return (
    <MuiAlert
      style={{ width: 500 }}
      elevation={6}
      variant="filled"
      {...props}
    />
  );
}

function Notification(props) {
  return (
    <Snackbar open={true} autoHideDuration={2000}>
      <Alert onClose={props.onClose} severity={props.level}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}

function NotificationBar(props) {
  if (props.error) {
    return (
      <Notification
        level="error"
        message={props.error}
        onClose={props.clearError}
      />
    );
  } else if (props.success) {
    return (
      <Notification
        level="success"
        message={props.success}
        onClose={props.clearSuccess}
      />
    );
  } else if (props.warning) {
    return (
      <Notification
        level="warning"
        message={props.warning}
        onClose={props.clearWarning}
      />
    );
  } else {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    error: state.NotificationStore.get("error"),
    success: state.NotificationStore.get("success"),
    warning: state.NotificationStore.get("warning"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearSuccess: () => dispatch(clearSuccess()),
    clearError: () => dispatch(clearError()),
    clearWarning: () => dispatch(clearWarning()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
