import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  listings: {
    height: 1000,
    overflow: "scroll",
  },
  filter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controls: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  control: {
    marginRight: 20,
  },
  apply: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
