import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Typography from "@material-ui/core/Typography";
import numeral from "numeral";

const Pie = (props) => {
  const total = Math.floor(
    props.series.map((s) => Math.abs(s.y)).reduce((a, b) => a + b)
  );

  function tooltip() {
    return `<p>${this.name}: <b>${numeral(this.y)
      .format("0.0a")
      .toUpperCase()}</b></p>`;
  }

  function formatter() {
    return `${this.point.name}</br>$${numeral(this.y)
      .format("0.0a")
      .toUpperCase()}`;
  }

  const options = {
    chart: {
      type: "pie",
      height: 230,
      width: 335,
      margin: [0, 0, 0, 0],
    },
    title: {
      text: `$${numeral(total).format("0.0a").toUpperCase()}`,
      style: {
        fontWeight: "bold",
        fontSize: 18,
      },
      y: 112,
    },

    plotOptions: {
      pie: {
        innerSize: "80%",
        size: "65%",
        showInLegend: true,
        dataLabels: {
          enabled: true,
          formatter: formatter,
          distance: "5",
          style: {
            fontSize: 10,
          },
        },
      },
    },
    series: [
      {
        data: props.series.map((s) => ({
          ...s,
          dataLabels: {
            enabled: true,
            formatter: formatter,
            distance: s.distance || "10",
          },
          y: Math.abs(s.y),
        })),
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
      align: "center",
      x: 0,
      y: 60,
    },
    tooltip: {
      headerFormat: "",
      pointFormatter: tooltip,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
      <Typography
        variant="subtitle2"
        style={{ fontWeight: "bold", marginTop: 10, marginBottom: 40 }}
      >
        {props.name}
      </Typography>
    </div>
  );
};

export default Pie;
