import React from "react";

import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";

import Tooltip from "../tooltip";

const Indicator = (props) => {
  const styles = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: props.color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff" /* Text color (you can change this) */,
    fontSize: "20px" /* Font size of content (you can change this) */,
    fontWeight: "bold",
    textTransform: "uppercase",
  };

  return (
    <Tooltip title={props.description} hidden={!props.description}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={styles}>
          <Icon fontSize="medium">{props.icon}</Icon>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 0,
          }}
        >
          {props.textIcon && (
            <Icon style={{ marginRight: 5 }}>{props.textIcon}</Icon>
          )}
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: "bold",
              fontSize: 20,
              marginBottom: 5,
            }}
          >
            {props.value}
          </Typography>
        </div>
        <Typography
          variant="subtitle1"
          style={{ fontSize: 15, marginBottom: 30, textAlign: "center" }}
        >
          {props.subvalue}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Indicator;
