import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";

const Types = {
  GET_RATES_START: "GET_RATES_START",
  GET_RATES_SUCCESS: "GET_RATES_SUCCESS",
  GET_RATES_ERROR: "GET_RATES_ERROR",
};

const { getRatesStart, getRatesSuccess, getRatesError } = createActions(
  Types.GET_RATES_START,
  Types.GET_RATES_SUCCESS,
  Types.GET_RATES_ERROR
);

const getRates = (state) => (dispatch, getState) => {
  dispatch(getRatesStart());

  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/r?state=${state}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch rates";
      dispatch(error(msg));

      return dispatch(
        getRatesError(err || { message: "Unable to fetch rates" })
      );
    }

    return dispatch(getRatesSuccess(JSON.parse(body)));
  });
};

export { Types, getRates };
