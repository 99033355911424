import { createActions } from "redux-actions";

import request from "../util/request";
import { error } from "../notification/actions";

const Types = {
  UPDATE_PRICE_RANGE: "UPDATE_PRICE_RANGE",
  UPDATE_PRICE: "UPDATE_PRICE",
  UPDATE_HOME_TYPE: "UPDATE_HOME_TYPE",
  UPDATE_BEDS: "UPDATE_BEDS",
  UPDATE_BATHS: "UPDATE_BATHS",
  UPDATE_HOA: "UPDATE_HOA",
  UPDATE_CITY: "UPDATE_CITY",
};

const {
  updatePriceRange,
  updatePrice,
  updateHomeType,
  updateBeds,
  updateBaths,
  updateHoa,
  updateCity,
} = createActions(
  Types.UPDATE_PRICE_RANGE,
  Types.UPDATE_PRICE,
  Types.UPDATE_HOME_TYPE,
  Types.UPDATE_BEDS,
  Types.UPDATE_BATHS,
  Types.UPDATE_HOA,
  Types.UPDATE_CITY
);

const getControls = (city) => (dispatch, getState) => {
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/l/metadata`,
    method: "POST",
    json: {
      city: city,
    },
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch listings metadata";
      dispatch(error(msg));
    }

    dispatch(updatePrice([body.minPrice, body.maxPrice]));
    dispatch(updatePriceRange([body.minPrice, body.maxPrice]));
  });
};

export {
  Types,
  updatePriceRange,
  updatePrice,
  updateHomeType,
  updateBeds,
  updateBaths,
  updateHoa,
  updateCity,
  getControls,
};
