import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "./style";

import { updateHomeType } from "../../../../stores/controls/actions";

const PriceControl = (props) => {
  const classes = styles();

  return (
    <Control id="price" name="Home Type" icon={<Icon>home</Icon>} {...props}>
      <div className={classes.root}>
        {props.homeType.map((h, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={h.enabled}
                name={h.value}
                onChange={(e) =>
                  props.updateHomeType(h.value, e.target.checked)
                }
              />
            }
            label={h.name}
          />
        ))}
      </div>
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.ControlsStore.get("loading"),
    homeType: state.ControlsStore.get("homeType"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateHomeType: (type, value) => dispatch(updateHomeType({ type, value })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceControl);
