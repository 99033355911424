import React, { useEffect } from "react";
import { connect } from "react-redux";

import Listing from "../listing";

import { getListing } from "../../stores/single/actions";
import { getAreas } from "../../stores/areas/actions";
import { getMetrics } from "../../stores/metrics/actions";

const HListing = (props) => {
  useEffect(() => {
    props.getAreas();
    props.getMetrics();
    props.getListing(props.match.params.id);
  }, [props.match.params.id]);

  return (
    <Listing
      loading={props.loading}
      listing={props.listing || {}}
      metrics={props.metrics || []}
      regions={props.regions || []}
    />
  );
};

function mapStateToProps(state) {
  return {
    loading:
      state.ListingStore.get("loading") || state.MetricsStore.get("loading"),
    listing: state.ListingStore.get("listing"),
    regions: state.RegionsStore.get("regions"),
    metrics: state.MetricsStore.get("metrics"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getListing: (id) => dispatch(getListing(id)),
    getAreas: () => dispatch(getAreas()),
    getMetrics: () => dispatch(getMetrics()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HListing);
