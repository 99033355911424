import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: "100%",
    height: 350,
    display: "flex",
    flexDirection: "column",
  },
  item: {
    cursor: "pointer",
    backgroundColor: "#D8D8D8",
  },
}));
