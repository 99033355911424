import React from "react";
import { Polygon } from "@react-google-maps/api";

const Area = ({ area, onClick }) => {
  const [hovered, setHovered] = React.useState(false);

  const getColor = () => {
    if (area.enabled) {
      return hovered ? "red" : "#8e99f3";
    } else {
      return hovered ? "gray" : "#000000";
    }
  };

  const options = {
    fillColor: getColor(),
    fillOpacity: 0.2,
    strokeColor: "#8e99f3",
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: true,
    zIndex: 1,
  };

  const polygon = area.area
    .match("POLYGON\\(\\((.*?)\\)\\)")[1]
    .split(", ")
    .map((s) => s.split(" "));

  return (
    <>
      <Polygon
        options={options}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onClick={() => onClick(area)}
        paths={polygon.map((c) => {
          return { lng: parseFloat(c[0]), lat: parseFloat(c[1]) };
        })}
      />
    </>
  );
};

export default Area;
