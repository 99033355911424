import React from "react";

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import styles from "./style";

const ImageCarousel = (props) => {
  const classes = styles();

  const options = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: "none",
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: false,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: "linear",
      overlayColor: "rgba(30, 30, 30, 0.9)",
      slideAnimationType: "fade",
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: "linear",
      usingPreact: false,
    },
    buttons: {},
    caption: {
      captionColor: "#a6cfa5",
      captionTextTransform: "uppercase",
    },
  };

  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <div className={classes.root}>
          <ImageList rowHeight={160} className={classes.imageList} cols={3}>
            {props.photos.map((photo) => (
              <ImageListItem className={classes.item} key={photo} cols={1}>
                <img src={photo} />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default ImageCarousel;
