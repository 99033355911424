import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import Range from "../../../common/range";
import Icon from "@material-ui/core/Icon";

import { updateBeds } from "../../../../stores/controls/actions";

const BedsControl = (props) => {
  return (
    <Control id="price" name="Bedrooms" icon={<Icon>bed</Icon>} {...props}>
      <Range range={props.beds} onChange={props.updateBeds} />
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    beds: state.ControlsStore.get("beds"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateBeds: (range) => dispatch(updateBeds(range)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BedsControl);
