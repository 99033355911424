import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";
import { getListings } from "../listings/actions";

const Types = {
  GET_AREAS_START: "GET_AREAS_START",
  GET_AREAS_SUCCESS: "GET_AREAS_SUCCESS",
  GET_AREAS_ERROR: "GET_AREAS_ERROR",
  UPDATE_AREA: "UPDATE_AREA",
  UPDATE_BOROUGH: "UPDATE_BOROUGH",
  TOGGLE_AREAS: "TOGGLE_AREAS",
};

const {
  getAreasStart,
  getAreasSuccess,
  getAreasError,
  updateArea,
  updateBorough,
  toggleAreas,
} = createActions(
  Types.GET_AREAS_START,
  Types.GET_AREAS_SUCCESS,
  Types.GET_AREAS_ERROR,
  Types.UPDATE_AREA,
  Types.UPDATE_BOROUGH,
  Types.TOGGLE_AREAS
);

const getAreas = (city) => (dispatch, getState) => {
  dispatch(getAreasStart());
  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/l/regions?city=${city}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch regions";
      dispatch(error(msg));
      return dispatch(
        getAreasError(err || { message: "Unable to fetch regions" })
      );
    }
    dispatch(getAreasSuccess(JSON.parse(body).regions));
    dispatch(getListings(city));
  });
};

export { Types, getAreas, updateArea, toggleAreas, updateBorough };
