import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  listings: [],
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [Types.GET_LISTINGS_START]: (state, action) => state.set("loading", true),
    [Types.GET_LISTINGS_SUCCESS]: (state, action) =>
      state
        .set("listings", action.payload)
        .set("loading", false)
        .set("error", null),
    [Types.GET_LISTINGS_ERROR]: (state, action) =>
      state.set("listings", []).set("loading", false).set("error", null),
  },
  INITIAL_STATE
);

export default reducer;
