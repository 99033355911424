import React from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";
import Typography from "@material-ui/core/Typography";
import Tooltip from "../tooltip";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const goodPoints = [
  [0.1, "#DF5353"],
  [0.5, "#DDDF0D"],
  [0.9, "#55BF3B"],
];

const badPoints = [
  [0.1, "#55BF3B"],
  [0.5, "#DDDF0D"],
  [0.9, "#DF5353"],
];

const Gauge = (props) => {
  const value = {
    yAxis: 0,
    showInLegend: false,
    data: [
      {
        name: "RPM",
        y: props.value,
        radius: props.outerRadius || 100,
        innerRadius: props.innerRadius || 85,
        dataLabels: {
          y: -16,
          borderWidth: 0,
          useHTML: true,
        },
      },
    ],
    dataLabels: {
      format:
        '<div style="text-align:center">' +
        `<span style="font-size:${props.fontSize || 16}px">${
          props.format || ""
        }</span><br/>` +
        "</span>" +
        "</div>",
    },
    hover: {
      enabled: false,
    },
  };

  const options = {
    chart: {
      type: "solidgauge",
      height: props.height || 120,
      width: props.height || 90,
      margin: [0, 0, 0, 0],
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "14px",
      },
      y: 150,
    },
    pane: {
      center: ["50%", "50%"],
      size: "100%",
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
        innerRadius: `${props.innerRadius}%` || "85%",
        outerRadius: `${props.outerRadius}%` || "100%",
        shape: "arc",
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
      positioner: function () {
        return { x: 80, y: 10 };
      },
    },
    yAxis: [
      {
        min: props.min || 0,
        max: props.max,
        stops: props.good ? goodPoints : badPoints,
        lineWidth: 0,
        tickWidth: 0,
        tickAmount: 2,
        minorTickInterval: null,
        title: {
          y: 0,
        },
        labels: {
          enabled: false,
          y: 16,
        },
      },
      {
        min: props.min || 0,
        max: props.max,
        lineWidth: 0,
        tickWidth: 0,
        tickAmount: 2,
        minorTickInterval: null,
        labels: {
          enabled: false,
          y: 16,
        },
      },
    ],
    series: [value],
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip title={props.description} hidden={!props.description}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Tooltip>
      <Typography
        variant="subtitle2"
        style={{ fontWeight: "bold", marginTop: 10, marginBottom: 0 }}
      >
        {props.name}
      </Typography>
    </div>
  );
};

export default Gauge;
