import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import styles from "./style";

const Tip = (props) => {
  const classes = styles();

  if (props.hidden) return props.children;

  return (
    <Tooltip
      arrow
      classes={classes}
      interactive
      placement={props.placement || "bottom"}
      title={
        <Typography className={classes.text} variant="caption">
          {props.title}
        </Typography>
      }
    >
      <div style={{ cursor: "pointer" }}>{props.children}</div>
    </Tooltip>
  );
};

const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "transparent",
  },
})(Tip);

export default TextOnlyTooltip;
