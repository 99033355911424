import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import SimpleReactLightbox from "simple-react-lightbox";

import App from "./components/app";
import NotificationStore from "./stores/notification";
import RegionsStore from "./stores/areas";
import ListingsStore from "./stores/listings";
import ListingStore from "./stores/single";
import ControlsStore from "./stores/controls";
import MetricsStore from "./stores/metrics";
import ComparablesStore from "./stores/comparables";
import RatesStore from "./stores/rates";
import PlacesStore from "./stores/places";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const stores = createStore(
  combineReducers({
    NotificationStore,
    RegionsStore,
    ListingsStore,
    ControlsStore,
    ListingStore,
    MetricsStore,
    RatesStore,
    ComparablesStore,
    PlacesStore,
  }),
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

ReactDOM.render(
  <Router>
    <Provider store={stores}>
      <SimpleReactLightbox>
        <App />
      </SimpleReactLightbox>
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
