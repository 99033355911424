import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  places: {
    description: "",
    groceries: [],
    busStations: [],
    subwayStations: [],
    restaurants: [],
    airports: [],
  },
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [Types.GET_PLACES_START]: (state, action) => state.set("loading", true),
    [Types.GET_PLACES_SUCCESS]: (state, action) =>
      state
        .set("places", action.payload)
        .set("loading", false)
        .set("error", null),
    [Types.GET_PLACES_ERROR]: (state, action) =>
      state
        .set("scores", {})
        .set("loading", false)
        .set("error", action.payload),
  },
  INITIAL_STATE
);

export default reducer;
