import React from "react";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import styles from "./style";

const Control = (props) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div {...props}>
      {isMobile && (
        <Button
          color="secondary"
          className={classes.button}
          variant="outlined"
          aria-describedby={props.id}
          color="primary"
          startIcon={props.icon}
          onClick={handleClick}
        >
          {props.name}
        </Button>
      )}
      {!isMobile && (
        <IconButton color="secondary" variant="outlined" onClick={handleClick}>
          <Icon>{props.icon}</Icon>
        </IconButton>
      )}
      <Popover
        className={classes.popover}
        id={props.id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.main}>{props.children}</div>
      </Popover>
    </div>
  );
};

export default Control;
