import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import Slider from "../../../common/slider";
import Icon from "@material-ui/core/Icon";

import { updateHoa } from "../../../../stores/controls/actions";

const HoaControl = (props) => {
  return (
    <Control id="price" name="HOA Fees" icon={<Icon>sell</Icon>} {...props}>
      <Slider
        title="HOA Range"
        min={0}
        max={5000}
        step={100}
        value={props.hoa}
        onChange={props.updateHoa}
      />
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    hoa: state.ControlsStore.get("hoa"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateHoa: (range) => dispatch(updateHoa(range)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HoaControl);
