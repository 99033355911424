import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  text: {
    padding: 3,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "pre-line",
  },

  tooltip: {
    backgroundColor: "#26418f",
  },
}));
