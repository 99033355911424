import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import model from "./homeFinance";
import Chart from "../../common/chart";
import Gauge from "../../common/gauge";

const CashFlow = (props) => {
  const { listing } = props;
  const specs = model({
    rent: props.rent,
    price: props.price,
    insurance: listing.insurance,
    taxes: listing.propertyTax,
    hoa: listing.hoaFees,
    n: props.years,
    resale: props.resale,
    downpaymentRate: props.downpayment / 100,
    mortgageRate: props.mortageRate / 100,
    extraPayments: props.extraPayments,
    yearlyAppreciation: props.appreciation / 100,
  });

  const xAxis = Array(parseInt(Math.max(props.years, props.resale)))
    .fill()
    .map((_, i) => i + new Date().getFullYear());

  const yAxis = [
    {
      name: "Resale",
      data: specs.map((s) => s.resale),
      stacking: "bar",
    },
    {
      name: "Downpayment",
      data: specs.map((s) => -s.downpayment),
      color: "#791B06",
      stacking: "bar",
    },
    {
      name: "Income",
      data: specs.map((s) => s.rentalIncome),
      color: "#8e99f3",
      stacking: "bar",
    },
    {
      name: "Interest",
      data: specs.map((s) => Math.trunc(s.interest)),
      color: "#960E0E",
      stacking: "bar",
    },
    {
      name: "Expenses",
      data: specs.map((s) => s.expenses - s.fees),
      color: "#D50A0A",
      stacking: "bar",
    },
    {
      name: "Taxes",
      data: specs.map((s) => s.propertyTaxes - s.rentalIncomeTax),
      color: "#FF0F0F",
      stacking: "bar",
    },
    {
      name: "Net Cash Flow",
      data: specs.map((s) => s.netIncome),
      type: "spline",
      color: "#65A0FF",
      stacking: "bar",
    },
  ];

  const description = "Shows the property's overall net cash flow";
  const breakeven_year = xAxis[specs.findIndex((s) => s.netIncome >= 0)];

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h5"> Cash Flow Analysis </Typography>
      </Grid>
      <Grid item xs={12}>
        <Chart x={xAxis} y={yAxis} />
      </Grid>
      {/*      <Grid item xs={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Gauge
          good
          name="Breakeven"
          format="{y}"
          min={0}
          value={breakeven_year}
          max={3}
          height={90}
          marginTop={0}
          fontSize={15}
          innerRadius={60}
          outerRadius={70}
        />

      </Grid>*/}
    </Grid>
  );
};

export default CashFlow;
