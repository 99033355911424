import React, { useEffect } from "react";
import { connect } from "react-redux";

import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "./style";
import theme from "./theme.json";

import Polygon from "./polygon";
import Marker from "./marker";
import Listing from "./listing";
import Detail from "./detail";
import PriceControl from "./controls/price";
import HomeControl from "./controls/home";
import BedsControl from "./controls/beds";
import BathsControl from "./controls/baths";
import RegionsControl from "./controls/regions";
import HoaControl from "./controls/hoa";
import CitiesControl from "./controls/cities";

import { getAreas, updateArea, toggleAreas } from "../../stores/areas/actions";
import { getListings } from "../../stores/listings/actions";
import { getControls } from "../../stores/controls/actions";

const CENTERS = {
  all: { lat: 41.343289, lng: -102.096586 },
  san_francisco: { lat: 37.761561, lng: -122.438604 },
  new_york: { lat: 40.746341, lng: -73.98977 },
  atlanta: { lat: 33.769319, lng: -84.384484 },
  austin: { lat: 30.279161, lng: -97.746643 },
  dallas: { lat: 32.78314, lng: -96.800576 },
  denver: { lat: 39.735214, lng: -104.982357 },
  saltlake: { lat: 40.760691, lng: -111.885381 },
  tampa: { lat: 27.9568, lng: -82.458473 },
  miami: { lat: 25.823628, lng: -80.262115 },
  chicago: { lat: 41.878141, lng: -87.630174 },
  montreal: { lat: 45.525621, lng: -73.611388 },
  dc: { lat: 38.90611, lng: -77.03861 },
  nashville: { lat: 36.160828, lng: -86.781182 },
  vegas: { lat: 36.175772, lng: -115.123749 }
};

const ZOOMS = {
  all: 4,
  san_francisco: 11,
  new_york: 11,
  atlanta: 11,
  austin: 11,
  dallas: 11,
  denver: 11,
  saltlake: 11,
  tampa: 11,
  miami: 12,
  chicago: 11,
  montreal: 11,
  dc: 12,
  nashville: 11,
  vegas: 11
};

const Search = (props) => {
  const classes = styles();

  const zoom = 12;

  useEffect(() => {
    props.getAreas(props.match.params.city);
    props.getControls(props.match.params.city);
  }, [props.city]);

  const [selected, setSelected] = React.useState(null);

  return (
    <div style={{ padding: 10, width: "100%" }}>
      <Paper elevation={5}>
        <Grid
          container
          spacing={3}
          style={{ paddingLeft: 10, paddingRight: 10 }}
        >
          <Grid item xs={9} style={{ display: "flex" }}>
            <HomeControl className={classes.control} />
            <PriceControl className={classes.control} />
            <BedsControl className={classes.control} />
            <BathsControl className={classes.control} />
            <RegionsControl className={classes.control} />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.getListings(props.match.params.city)}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        spacing={3}
        style={{
          height: window.innerHeight - 150,
          marginTop: 10,
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={9}
          style={{
            height: window.innerHeight - 150,
            marginTop: 10,
            position: "relative",
          }}
        >
          <Paper elevation={10} className={classes.root}>
            <LoadScript googleMapsApiKey="AIzaSyBmLv5cguT0syxbojA1Ro1Sm4sbUlprwjQ">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={CENTERS[props.match.params.city]}
                zoom={ZOOMS[props.match.params.city]}
                options={{
                  styles: theme,
                  scrollwheel: false,
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  panControl: true,
                  minZoom: 4,
                  maxZoom: 15,
                }}
              >
                {props.regions.map((r) => (
                  <Polygon
                    area={r}
                    onClick={() => props.updateArea(r.id, !r.enabled)}
                  />
                ))}
                {props.listings.map((l, i) => (
                  <Marker rank={i + 1} lat={l.latitude} lng={l.longitude} />
                ))}
              </GoogleMap>
              <Paper
                elevation={10}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 50,
                  right: 50,
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ cursor: "pointer" }}
                      checked={props.regions.every((r) => r.enabled)}
                      onChange={() =>
                        props.toggleAreas(
                          !props.regions.every((r) => r.enabled)
                        )
                      }
                      name="checkedB"
                      color="secondary"
                    />
                  }
                  label="Select All"
                />
              </Paper>
            </LoadScript>
          </Paper>
        </Grid>
        <Grid
          style={{
            height: window.innerHeight - 150,
            overflowY: "scroll",
            marginTop: 10,
          }}
          item
          xs={12}
          md={3}
        >
          {props.listings.map((l, i) => (
            <Listing
              id={l.id}
              rank={i + 1}
              listing={l}
              region={props.regions.find((r) => r.id === l.regionId)}
              history={props.history}
              onClick={() => setSelected(l)}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );

  // return (
  //   <Grid style={{ height: "100%" }} container spacing={3}>
  //     <Paper style={{ width: "100%", margin: 10, padding: 10, display: "flex", alignItems: "center" }}>
  //       <Grid container className={classes.filters}>
  //         <Grid className={classes.controls} item xs={10}>
  //           <HomeControl className={classes.control} />
  //           <PriceControl className={classes.control} />
  //           <BedsControl className={classes.control} />
  //           <BathsControl className={classes.control} />
  //         </Grid>
  //         <Grid className={classes.apply} item xs={2}>
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             onClick={() => props.getListings(props.match.params.city)}
  //           >
  //             Search
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </Paper>

  //     <Grid container spacing={3} style={{ padding: 10 }}>
  //       <Grid item xs={12} md={9}>
  //         <Paper elevation={10} className={classes.root}>
  //           <LoadScript googleMapsApiKey="AIzaSyBmLv5cguT0syxbojA1Ro1Sm4sbUlprwjQ">
  //             <GoogleMap
  //               mapContainerStyle={{ height: "100%", width: "100%" }}
  //               center={CENTERS[props.match.params.city]}
  //               zoom={ZOOMS[props.match.params.city]}
  //               options={{
  //                 styles: theme,
  //                 scrollwheel: false,
  //                 fullscreenControl: false,
  //                 streetViewControl: false,
  //                 mapTypeControl: false,
  //                 panControl: true,
  //                 minZoom: 4,
  //                 maxZoom: 15,
  //                 // restriction: {
  //                 //   latLngBounds: bounds,
  //                 // }
  //               }}
  //             >

  //               {props.regions.map((r) => (
  //                 <Polygon
  //                   area={r}
  //                   onClick={() => props.updateArea(r.id, !r.enabled)}
  //                 />
  //               ))}
  //               {props.listings.map((l, i) => (
  //                 <Marker rank={i + 1} lat={l.latitude} lng={l.longitude} />
  //               ))}
  //             </GoogleMap>
  //           </LoadScript>
  //         </Paper>
  //       </Grid>
  //       <Grid className={classes.listings} item xs={12} md={3}>
  //         {props.listings.map((l, i) => (
  //           <Listing
  //             id={l.id}
  //             rank={i + 1}
  //             listing={l}
  //             region={props.regions.find((r) => r.id === l.regionId)}
  //             history={props.history}
  //             onClick={() => setSelected(l)}
  //           />
  //         ))}
  //       </Grid>
  //     </Grid>
  //     <Detail
  //       open={Boolean(selected)}
  //       onClose={() => setSelected(null)}
  //       listing={selected}
  //     />
  //   </Grid>
  // );
};

function mapStateToProps(state) {
  return {
    loading: state.RegionsStore.get("loading"),
    regions: state.RegionsStore.get("regions"),
    listings: state.ListingsStore.get("listings"),
    changed: state.RegionsStore.get("changed"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAreas: (id) => dispatch(getAreas(id)),
    updateArea: (type, value) => dispatch(updateArea({ type, value })),
    toggleAreas: (val) => dispatch(toggleAreas(val)),
    getListings: (id) => dispatch(getListings(id)),
    getControls: (city) => dispatch(getControls(city)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
