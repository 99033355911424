import { createActions } from "redux-actions";
import request from "../util/request";
import { error } from "../notification/actions";

const Types = {
  GET_COMP_DETAIL_START: "GET_COMP_DETAIL_START",
  GET_COMP_DETAIL_SUCCESS: "GET_COMP_DETAIL_SUCCESS",
  GET_COMP_DETAIL_ERROR: "GET_COMP_DETAIL_ERROR",
};

const { getCompDetailStart, getCompDetailSuccess, getCompDetailError } =
  createActions(
    Types.GET_COMP_DETAIL_START,
    Types.GET_COMP_DETAIL_SUCCESS,
    Types.GET_COMP_DETAIL_ERROR
  );

const getCompDetail = (id) => (dispatch, getState) => {
  dispatch(getCompDetailStart());

  const url = process.env.REACT_APP_ENDPOINT || "http://localhost:3000";
  const options = {
    uri: `${url}/z/${id}`,
    method: "GET",
  };

  request(options, dispatch, (err, response, body) => {
    if (err || response.statusCode !== 200) {
      const msg = err || "Unable to fetch comp detail";
      dispatch(error(msg));

      return dispatch(
        getCompDetailError(err || { message: "Unable to fetch comp detail" })
      );
    }

    return dispatch(
      getCompDetailSuccess({
        listing: JSON.parse(body).listing,
        comps: getState().ListingStore.get("listing").comparables,
      })
    );
  });
};

export { Types, getCompDetail };
