import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  loading: false,
  city: "all",
  priceRange: [0, 10000000],
  price: [0, 10000000],
  hoa: [0, 5000],
  beds: [0, 10],
  baths: [0, 10],
  homeType: [
    {
      name: "Condo",
      value: "Condo",
      enabled: true,
    },
    {
      name: "Cooperative",
      value: "Cooperative",
      enabled: true,
    },
    {
      name: "Multi Family",
      value: "MultiFamily",
      enabled: true,
    },
    {
      name: "Townhouse",
      value: "Townhouse",
      enabled: true,
    },
    {
      name: "Apartment",
      value: "Apartment",
      enabled: true,
    },
    {
      name: "Single Family",
      value: "SingleFamily",
      enabled: true,
    },
  ],
});

const reducer = handleActions(
  {
    [Types.UPDATE_PRICE_RANGE]: (state, action) =>
      state.set("priceRange", action.payload),
    [Types.UPDATE_PRICE]: (state, action) => state.set("price", action.payload),
    [Types.UPDATE_HOA]: (state, action) => state.set("hoa", action.payload),
    [Types.UPDATE_BEDS]: (state, action) => state.set("beds", action.payload),
    [Types.UPDATE_BATHS]: (state, action) => state.set("baths", action.payload),
    [Types.UPDATE_CITY]: (state, action) => state.set("city", action.payload),
    [Types.UPDATE_HOME_TYPE]: (state, action) => {
      const index = state
        .get("homeType")
        .findIndex((h) => h.value === action.payload.type);
      const types = state.get("homeType");
      types[index] = { ...types[index], enabled: action.payload.value };
      return state.set("homeType", types).set("loading", !state.get("loading"));
    },
  },
  INITIAL_STATE
);

export default reducer;
