import React from "react";
import numeral from "numeral";
import MuiMarkdown from "mui-markdown";
import { toWords } from "number-to-words";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import Carousel from "../common/carousel";
import Board from "../common/board";
import Stat from "../common/stat";
import Gauge from "../common/gauge";
import TotalsChart from "./charts/totals";
import CashFlowChart from "./charts/cashflow";
import Comparable from "./comparable";
import Insights from "./insights";
import Metrics from "./metrics";
import Map from "./map";

import styles from "./style";

const Info = (props) => (
  <Grid item xs={6} md={4} lg={2}>
    <Stat {...props} />
  </Grid>
);

const Control = (props) => (
  <Grid item xs={6} spacing={4} style={{ marginTop: 20 }}>
    <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
      {props.name}
    </Typography>
    {props.children}
  </Grid>
);

const words = (num) => (num === 0 ? "Studio" : toWords(num));

const Listing = (props) => {
  const classes = styles();

  const [years, setYears] = React.useState(30);
  const [listingPrice, setListingPrice] = React.useState(props.listing.price);
  const [downpayment, setDownPayment] = React.useState(20);
  const [extraPayments, setExtraPayments] = React.useState(0);
  const [rent, setRent] = React.useState(props.listing.rent);
  const [appreciation, setAppreciation] = React.useState(3);
  const [resale, setResale] = React.useState(30);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chosenRent, setChosenRent] = React.useState(0);

  const rate = props.rates[years] || 3;
  const [mortageRate, setMortageRate] = React.useState(rate.apr);

  const photos = props.listing?.photos || [];
  const borough = (props.regions || []).find(
    (r) => r.id === props.listing.regionId
  )?.borough;

  // const region = (props.regions || []).find(
  //   (r) => r.id === props.listing.regionId
  // )?.id;
  // const regionName = (props.regions || []).find(
  //   (r) => r.id === props.listing.regionId
  // )?.neighborhood;

  // const metrics = (props.metrics || []).find((m) => m.regionId === region);

  const resaleOptions = Array(30)
    .fill()
    .map((_, i) => ({ index: i + 1, year: i + 1 + new Date().getFullYear() }));

  const page = (
    <>
      <Dialog
        open={!rent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Set a Monthly Rental Estimate"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Our systems could not predict a montly rental income for the
            property. Set one to view the full financial analysis.
          </DialogContentText>
          <Control name="Monthly Rent">
            <TextField
              style={{ width: 150, marginRight: 10 }}
              id="outlined-number"
              type="number"
              variant="outlined"
              margin="dense"
              defaultValue={0}
              value={rent}
              onChange={(e) => setChosenRent(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0, max: 1000000000, step: 100 },
              }}
            />
          </Control>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRent(parseFloat(chosenRent))}
            disabled={!chosenRent}
            color="primary"
            autoFocus
          >
            Set
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} md={7}>
          <Board loading={props.loading} icon="home" title="The Basics">
            <div className={classes.header}>
              <div>
                <Typography
                  className={classes.address}
                  variant="h5"
                  gutterBottom
                >
                  {`${props.listing.address}, ${props.listing.city}, ${props.listing.state}, ${props.listing.zipCode}`}
                </Typography>
                <Typography
                  className={classes.address}
                  variant="h6"
                  gutterBottom
                >
                  {borough}
                </Typography>
              </div>
              <Chip
                avatar={<Avatar alt="zillow" src="/assets/zillow.png"></Avatar>}
                label="Zillow"
                onClick={() => window.open(`https://${props.listing.url}`)}
              />
            </div>
            <Paper style={{ padding: 5 }} elevation={0}>
              <Carousel photos={photos} />
            </Paper>
            <Grid container spacing={3} className={classes.stats}>
              <Info
                icon="sell"
                value={`$${numeral(props.listing.price)
                  .format("0.0a")
                  .toUpperCase()}`}
                description="The property's asking price"
              />
              <Info
                icon="apartment"
                value={props.listing.homeType}
                description="The property's home type"
              />
              <Info
                icon="beds"
                value={words(props.listing.bedrooms || 0)}
                description="The property's number of bedrooms"
              />
              <Info
                icon="bathtub"
                value={toWords(props.listing.bathrooms || 0)}
                description="The property's number of bathrooms"
              />
              <Info
                icon="crop"
                value={`${props.listing.livingArea || "--"} SQFT`}
                description="The property's square footage"
              />
            </Grid>
          </Board>

          <Board
            loading={props.loading}
            icon="trending_up"
            title="The Financials"
            action={
              <IconButton
                style={{ padding: 0 }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Icon>settings</Icon>
              </IconButton>
            }
          >
            <Grid container spacing={2}>
              <Popover
                style={{ marginLeft: 30 }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Grid container spacing={0} style={{ width: 450, padding: 20 }}>
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: "bold" }} variant="h6">
                      Mortage Options
                    </Typography>
                  </Grid>
                  <Control name="Years">
                    <FormControl
                      style={{ width: 150, marginRight: 10 }}
                      variant="outlined"
                      margin="dense"
                    >
                      <Select
                        native
                        value={years}
                        onChange={(e) => {
                          setMortageRate(props.rates[e.target.value].apr);
                          setYears(e.target.value);
                        }}
                      >
                        <option value={10}>10 Years</option>
                        <option value={15}>15 Years</option>
                        <option value={20}>20 Years</option>
                        <option value={30}>30 Years</option>
                      </Select>
                    </FormControl>
                  </Control>
                  <Control name="Price">
                    <TextField
                      style={{ width: 150 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      name="Price"
                      defaultValue={listingPrice}
                      value={listingPrice}
                      onChange={(e) => setListingPrice(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 1000000000, step: 10000 },
                      }}
                    />
                  </Control>
                  <Control name="Downpayment">
                    <TextField
                      style={{ width: 150 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      name="Downpayment"
                      defaultValue={10}
                      value={downpayment}
                      onChange={(e) => setDownPayment(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        inputProps: { min: 5, max: 100 },
                      }}
                    />
                  </Control>
                  <Control name="Interest Rate">
                    <TextField
                      style={{ width: 150 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      name="Interest Rate"
                      defaultValue={10}
                      value={mortageRate}
                      onChange={(e) => setMortageRate(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                      }}
                    />
                  </Control>
                  <Control name="Extra M. Payments">
                    <TextField
                      style={{ width: 150, marginRight: 10 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      defaultValue={0}
                      value={extraPayments}
                      onChange={(e) => setExtraPayments(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 1000000000, step: 100 },
                      }}
                    />
                  </Control>
                  <Grid item xs={12} style={{ marginTop: 30 }}>
                    <Typography style={{ fontWeight: "bold" }} variant="h6">
                      Cash Flow Options
                    </Typography>
                  </Grid>
                  <Control name="Monthly Rent">
                    <TextField
                      style={{ width: 150, marginRight: 10 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      defaultValue={0}
                      value={rent}
                      onChange={(e) => setRent(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 1000000000, step: 100 },
                      }}
                    />
                  </Control>
                  <Control name="Yearly Appreciation">
                    <TextField
                      style={{ width: 150, marginRight: 10 }}
                      id="outlined-number"
                      type="number"
                      variant="outlined"
                      margin="dense"
                      defaultValue={0}
                      value={appreciation}
                      onChange={(e) => setAppreciation(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                      }}
                    />
                  </Control>
                  <Control name="Resale">
                    <FormControl
                      style={{ width: 150, marginRight: 10 }}
                      variant="outlined"
                      margin="dense"
                    >
                      <Select
                        native
                        value={resale}
                        onChange={(e) => setResale(e.target.value)}
                      >
                        {resaleOptions.map((y, i) => (
                          <option key={i} value={y.index}>
                            {y.year}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Control>
                </Grid>
              </Popover>
              <Grid style={{ marginBottom: 60 }} item xs={12}>
                <TotalsChart
                  rent={rent}
                  listing={props.listing}
                  price={parseInt(listingPrice)}
                  downpayment={downpayment}
                  mortageRate={mortageRate}
                  years={years}
                  extraPayments={extraPayments}
                  resale={resale}
                  appreciation={appreciation}
                />
              </Grid>
              {/*            <Grid item xs={12}>
              <LoanChart
                listing={props.listing}
                price={parseInt(listingPrice)}
                downpayment={downpayment}
                mortageRate={mortageRate}
                years={years}
                extraPayments={extraPayments}
              />
            </Grid>
            <Grid item xs={12}>
              <BurnChart
                rent={rent}
                listing={props.listing}
                price={parseInt(listingPrice)}
                downpayment={downpayment}
                mortageRate={mortageRate}
                years={years}
                extraPayments={extraPayments}
                onChangeRent={setRent}
              />
            </Grid>*/}
              <Grid item xs={12}>
                <CashFlowChart
                  rent={rent}
                  listing={props.listing}
                  price={parseInt(listingPrice)}
                  downpayment={downpayment}
                  mortageRate={mortageRate}
                  years={years}
                  extraPayments={extraPayments}
                  onChangeRent={setRent}
                  appreciation={appreciation}
                  resale={resale}
                />
              </Grid>
            </Grid>
          </Board>
        </Grid>

        <Grid item xs={12} md={5}>
          <Board loading={props.loading} icon="lightbulb" title="The Insights">
            <Insights listing={props.listing} comps={props.comparables} />
          </Board>
          {/*
        <Board icon="lightbulb" title="The Rates">
          <Rates rates={props.rates} />
        </Board>*/}

          <Board loading={props.loading} icon="donut_large" title="The Metrics">
            <Metrics listing={props.listing} rent={props.listing.rent} />
          </Board>
          <Board icon="compare" title="The Comparables">
            <div style={{ display: "flex", overflowX: "auto" }}>
              {props.listing.comparables?.length === 0 && (
                <Typography>No comparable listing found in the area</Typography>
              )}
              {props.comparables.map((cmp, i) => (
                <div style={{ margin: 10 }}>
                  <Comparable id={cmp.id} listing={cmp} rank={i + 1} />
                </div>
              ))}
            </div>
          </Board>
          <Board
            loading={props.places_loading}
            icon="explore"
            title={`The NEIGHBORHOOD`}
          >
            <Map listing={props.listing} places={props.places} />
            <div className={classes.scores}>
              {/*              {Object.keys(props.scores).map((s) => (
                <Gauge
                  good
                  height={60}
                  className={classes.score}
                  name={s.toUpperCase()}
                  format="{y:.0f}"
                  min={0}
                  value={props.scores[s]}
                  max={100}
                />
              ))}*/}
            </div>
          </Board>

          {/*<Board icon="donut_large" title="The Metrics">
          <Grid className={classes.metrics} container spacing={0}>
            <Grid className={classes.metric} item xs={12} md={6}>
              <Gauge
                good
                name="Estimated Rent"
                min={0}
                format="${y}"
                value={props.listing?.rent}
                average={
                  metrics?.rent.find(
                    (r) => r.bedrooms === props.listing.bedrooms
                  ).avg
                }
                max={
                  metrics?.rent.find(
                    (r) => r.bedrooms === props.listing.bedrooms
                  ).max
                }
              />
            </Grid>
            <Grid className={classes.metric} item xs={12} md={6}>
              <Gauge
                name="HOA Fees"
                min={0}
                format="${y}"
                value={props.listing?.hoaFees}
                max={metrics?.hoa.max}
              />
            </Grid>
            <Grid className={classes.metric} item xs={12} md={6}>
              <Gauge
                good
                name="Rental Yield"
                value={props.listing.netRentYield * 100}
                format="{y:.1f}%"
                max={metrics?.netRentYield.max * 100}
              />
            </Grid>
            <Grid className={classes.metric} item xs={12} md={6}>
              <Gauge
                name="Fees Ratio"
                value={props.listing.feesToRentRatio * 100}
                format="{y:.1f}%"
                max={metrics?.feesToRentRatio.max * 100}
              />
            </Grid>
          </Grid>
        </Board>*/}
        </Grid>
      </Grid>
    </>
  );

  return page;
};

export default React.memo(Listing);
