import React from "react";
import { withRouter } from "react-router-dom";

import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";

import styles from "./style";

const MiniDrawer = function MiniDrawer(props) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={clsx(classes.appBar)}>
        <Toolbar className={classes.bar}>
          <div className={classes.menuButton}>
            <a href="/">
              <img alt="logo" src="/assets/logo-white.png" width="30" />
            </a>
          </div>
          {/*<div className={classes.actions}>
            <Menu
              icon={<SettingsIcon color="white" />}
              options={menuOptions}
              onClick={(page) => props.history.push(page.url)}
            />
          </div>*/}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.main}>{props.children}</div>
      </main>
    </div>
  );
};

export default withRouter(MiniDrawer);
