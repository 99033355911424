import React from "react";
import TextField from "@material-ui/core/TextField";

import styles from "./style";

const Range = (props) => {
  const classes = styles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        id="outlined-required"
        label="Min"
        variant="outlined"
        size="small"
        value={props.range[0]}
        onChange={(e) => props.onChange([e.target.value, props.range[1]])}
      />
      <p> - </p>
      <TextField
        className={classes.input}
        id="outlined-required"
        label="Max"
        variant="outlined"
        size="small"
        value={props.range[1]}
        onChange={(e) => props.onChange([props.range[0], e.target.value])}
      />
    </div>
  );
};

export default Range;
