import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import IOSSlider from "../../../common/slider";
import Icon from "@material-ui/core/Icon";

import { updatePrice } from "../../../../stores/controls/actions";

const PriceControl = (props) => {
  return (
    <Control id="price" name="Price" icon={<Icon>sell</Icon>} {...props}>
      <IOSSlider
        title="Price Range"
        range={props.range}
        value={props.price}
        onChange={props.updatePrice}
      />
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    price: state.ControlsStore.get("price"),
    range: state.ControlsStore.get("priceRange"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePrice: (range) => dispatch(updatePrice(range)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceControl);
