import request from "request";
import respond from "./response";

const r = (options, dispatch, callback) => {
  request(options, (err, response, body) => {
    respond(err, response, dispatch, () => {
      callback(err, response, body);
    });
  });
};

export default r;
