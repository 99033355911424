import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  main: {
    padding: 20,
  },
  popover: {
    marginTop: 10,
  },
  button: {
    fontWeight: "bold",
    border: "1.5px solid",
    "&:hover": {
      border: "1.5px solid",
    },
  },
}));
