import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  controls: {
    marginBottom: 10,
  },
  control: {
    marginBottom: 10,
  },
  totals: {
    display: "flex",
    justifyContent: "center",
  },
}));
