import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  success: null,
  error: null,
  warning: null,
  loading: false,
});

const reducer = handleActions(
  {
    [Types.SUCCESS]: (state, action) =>
      state
        .set("success", action.payload)
        .set("error", null)
        .set("warning", null),

    [Types.ERROR]: (state, action) =>
      state
        .set("error", action.payload)
        .set("success", null)
        .set("warning", null),
    [Types.WARNING]: (state, action) =>
      state
        .set("warning", action.payload)
        .set("error", null)
        .set("warning", null),
    [Types.LOADING]: (state, action) => state.set("loading", true),
    [Types.CLEAR_LOADING]: (state, action) => state.set("loading", false),
    [Types.CLEAR_SUCCESS]: (state, action) => state.set("success", null),
    [Types.CLEAR_ERROR]: (state, action) => state.set("error", null),
    [Types.CLEAR_WARNING]: (state, action) => state.set("warning", null),
  },
  INITIAL_STATE
);

export default reducer;
