import React from "react";
import { Marker } from "@react-google-maps/api";

const Listing = ({ rank, lat, lng, onClick }) => {
  return (
    <Marker
      icon={{
        path: `M -10, 0 a 10,10 0 1,1 20,0 a 10,10 0 1,1 -20,0`,
        fillOpacity: 1,
        scale: 1,
        fillColor: "#ff7961",
      }}
      label={{
        text: `${rank}`,
        fontFamily: "Open Sans",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#FFFFFF",
      }}
      position={{ lng: lng, lat: lat }}
      onClick={onClick}
      onMouseOver={(e) => {
        e.domEvent.stopPropagation();
      }}
    />
  );
};

export default Listing;
