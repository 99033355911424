import React from "react";
import { connect } from "react-redux";

import Control from "../../../common/control";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import styles from "./style";

import { updateBorough } from "../../../../stores/areas/actions";

const RegionsControl = (props) => {
  const classes = styles();

  return (
    <Control id="regions" name="Regions" icon={<Icon>explore</Icon>} {...props}>
      <div className={classes.root}>
        {props.regions.map((r, i) => (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={r.enabled}
                name={r.neighborhood}
                onChange={(e) =>
                  props.updateBorough(r.neighborhood, e.target.checked)
                }
              />
            }
            label={r.neighborhood}
          />
        ))}
      </div>
    </Control>
  );
};

function mapStateToProps(state) {
  return {
    regions: state.RegionsStore.get("regions"),
    changed: state.RegionsStore.get("changed"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateBorough: (type, value) => dispatch(updateBorough({ type, value })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionsControl);
