import { handleActions } from "redux-actions";
import Immutable from "immutable";

import { Types } from "./actions";

const INITIAL_STATE = Immutable.Map({
  comps_details: [],
  loading: false,
  error: null,
});

const reducer = handleActions(
  {
    [Types.GET_COMP_DETAIL_SUCCESS]: (state, action) =>
      state
        .set(
          "comps_details",
          [...state.get("comps_details"), action.payload.listing].filter(
            (comp) => comp.homeType !== "Unknown"
          )
        )
        .set(
          "loading",
          state.get("comps_details").length < action.payload.comps.length - 1
        )
        .set("error", null),

    [Types.GET_COMP_DETAIL_ERROR]: (state, action) =>
      state.set("comps", {}).set("loading", false).set("error", action.payload),
  },
  INITIAL_STATE
);

export default reducer;
