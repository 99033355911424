import React, { useEffect } from "react";
import { connect } from "react-redux";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Listing from "../listing";

import { getZillowListing } from "../../stores/single/actions";
import { getAreas } from "../../stores/areas/actions";
import { getMetrics } from "../../stores/metrics/actions";

const HListing = (props) => {
  useEffect(() => {
    props.getListing(props.match.params.id);
  }, [props.match.params.id]);

  const loading = (
    <div
      style={{
        position: "fixed",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <Typography variant="h5" style={{ marginTop: 20 }}>
        Analyzing Your Listing
      </Typography>
    </div>
  );

  return props.loading ? (
    loading
  ) : (
    <Listing
      loading={props.loading}
      comps_loading={props.comps_loading}
      insights_loading={props.insights_loading}
      places_loading={props.places_loading}
      listing={props.listing}
      places={props.places}
      metrics={props.metrics}
      regions={props.regions}
      comps={props.comps}
      comparables={props.comparables}
      rates={props.rates}
    />
  );
};

function mapStateToProps(state) {
  return {
    loading:
      state.ListingStore.get("loading") || state.RatesStore.get("loading"),
    places_loading: state.PlacesStore.get("loading"),
    listing: state.ListingStore.get("listing"),
    places: state.PlacesStore.get("places"),
    regions: state.RegionsStore.get("regions"),
    metrics: state.MetricsStore.get("metrics"),
    comparables: state.ComparablesStore.get("comps_details"),
    rates: state.RatesStore.get("rates"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getListing: (id) => dispatch(getZillowListing(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HListing);
