import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    height: "80%",
    width: "80%",
    outline: "none",
  },
  images: {
    overflow: "scroll",
    display: "flex",
    flexDirection: "row",
  },
}));
